
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isUserAvailable: "getIsUserAvailable",
      tokenExpired: "getTokenExpired",
      msalObj: "getMsalObj"
    }),
    showInactivityPopUp () {
      if (this.isUserAvailable && this.tokenExpired) {
        return true;
      }
      return false;
    }
  },
  methods: {
    async refreshToken () {
      await this.$store.dispatch("refreshExpiredToken");
    },
    sendToLoginPage () {
      this.msalObj.logoutRedirect({
        account: this.msalObj.getAccountByUsername(this.msalObj.getActiveAccount().username),
        postLogoutRedirectUri: '/login'
      });
    }
  }
};
