import axios, { AxiosInstance } from "axios";

class ScoreboardService {
    axios: AxiosInstance;
    auth: string;

    setBaseUrl (baseAPI: string) {
      const url = new URL(baseAPI);
      this.auth = url.searchParams.get('code');
      const baseURL = `https://${url.hostname}/`;
      this.axios = axios.create({
        baseURL,
        headers: {
          'Content-Type': 'application/json'
        }
      });
    }

    getUri (func: string) {
      let uri = `api/${func}`;
      if (this.auth) {
        uri += `?code=${this.auth}`;
      }
      return uri;
    }

    async healthcheck () {
      try {
        const result = await this.axios.get(this.getUri('HealthCheck'));
        return {
          success: result.status === 200,
          message: result.data
        };
      } catch (error) {
        return {
          success: false,
          message: error.toString()
        };
      }
    }

    async getData () {
      try {
        const query = await this.axios.post(this.getUri('GetScoreBoard'));
        return {
          success: query.status === 200,
          data: query.data
        };
      } catch (e) {
        return {
          success: false,
          message: e.toString()
        };
      }
    }
}

export default ScoreboardService;
