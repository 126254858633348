import { render, staticRenderFns } from "./ScoreboardListReport.vue?vue&type=template&id=08b8228b&"
import script from "./ScoreboardListReport.vue?vue&type=script&lang=ts&"
export * from "./ScoreboardListReport.vue?vue&type=script&lang=ts&"
import style0 from "./ScoreboardListReport.vue?vue&type=style&index=0&id=08b8228b&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports