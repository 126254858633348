
import ScoreboardReportStatus from "@/components/ScoreboardReportStatus.vue";

export default {
  props: {
    report: Object,
    study: Object,
    index: Number
  },
  data () {
    return {
      isCollapsed: true
    };
  },
  components: {
    ScoreboardReportStatus
  },
  computed: {
    first_result () {
      if (this.study.first_result === undefined) {
        return "";
      }
      return this.study.first_result + "Z";
    },
    sent_dt () {
      if (this.report.sent_dt === undefined) {
        return "";
      }
      return this.report.sent_dt + "Z";
    },
    canCollapse () {
      if (this.index !== 0) {
        return true;
      }
      return false;
    },
    rowId () {
      if (this.index !== 0) {
        return this.study.accession;
      }
      return "";
    }
  },
  methods: {
    getSoftware (studySoftware: string, version?: string) {
      if (version) {
        studySoftware = studySoftware + ": " + version;
      }
      if (studySoftware.includes("EchoGo")) {
        return studySoftware.substring(7);
      }
      return studySoftware;
    },
    hasTooltipValue (value: string) {
      if (value) {
        return "tooltip-text";
      }
    },
    getStatus (study) {
      let status = "";
      study.results.forEach(report => {
        if (report.pending) {
          status = "pending";
        } else if (
          (report.accepted && this.getSoftware(report.software) === "Core") ||
          (report.accepted && this.getSoftware(report.software) === "Heart Failure") ||
          (report.reason && this.getSoftware(report.software) === "Core")
        ) {
          status = "finished";
        }
      });
      if (study.results.length === 0) {
        status = "pending";
      }
      return status;
    },
    clickCollapse () {
      this.isCollapsed = !this.isCollapsed;
    }
  }
};
